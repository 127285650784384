import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
   // 聊天机器人
   {
    path: '/',
    name: 'html',
    component: () => import('@/components/Html-w.vue')
  },
  {
    path: '/honee',
    name: 'honee',
    component: () => import('@/components/honee/index.vue')
  },
   // 聊天机器人
   {
    path: '/honnev',
    name: 'honnev',
    component: () => import('@/components/honee/honne.vue')
  },
   // 聊天机器人
   {
    path: '/iude',
    name: 'iude',
    component: () => import('@/components/iude.vue')
  },
   // 聊天机器人
   {
    path: '/function',
    name: 'function',
    component: () => import('@/components/function.vue')
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;